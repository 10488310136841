import React from "react"
import axios from 'axios';


const initialState = {
  name: "",
  email: "",
  message: "",
  success: false,
}

class Contact extends React.Component {

  state = {...initialState};

  onChange = (e) => {
    let temp = {}
    temp[e.target.name] = e.target.value;
    this.setState(temp)
  }

  submit = (e) => {

    const success = () => {
      this.setState({success: true})
      setTimeout( () =>   this.setState({success: false}) , 3000);
    }

    e.preventDefault();
    let data = new FormData();
    data.append('entry.266629880', this.state.name);
    data.append('entry.1866058743', this.state.email);
    data.append('entry.1274868119', this.state.message);
    axios.post('https://docs.google.com/forms/d/e/1FAIpQLSdE0dnooTJ-teHobXaViI0Y2uzX5ed7wtRCZd3UXa-MYZC1bg/formResponse',data)
      .then(res => {
        if (res.status === 200) {
        } else {
        }
        this.setState(initialState);
      })
      .catch(err => {
        this.setState(initialState);
        success()
      })
  }

  render() {
    return (
      <section id="contact" className="module module-gray">
        <div className="container">
          <div className="module-header" data-sal="slide-up">
            <h2 className="module-title">Contact</h2>
            <div className="module-line"></div>
          </div>
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <form id="contact-form" action="#" onSubmit={this.submit}>
                <div className="ajax-hidden">
                  <div className="form-group" data-sal="slide-up">
                    <label className="sr-only" htmlFor="c_name">Name</label>
                    <input type="text" id="c_name" className="form-control" name="name" placeholder="Name" required
                           value={this.state.name}
                           onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group" data-sal="slide-up" data-sal-delay="100">
                    <label className="sr-only" htmlFor="c_email">Email address</label>
                    <input type="email" id="c_email" className="form-control" name="email" placeholder="E-mail"
                           required value={this.state.email}
                           onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group" data-sal="slide-up" data-sal-delay="200">
                  <textarea className="form-control" id="c_message" name="message" rows="7" placeholder="Your message"
                            required value={this.state.message}
                            onChange={this.onChange}
                  ></textarea>
                  </div>
                  <button type="submit" className="btn btn-block btn-custom-2" data-sal="slide-up" data-sal-delay="300">Send
                    Message
                  </button>
                </div>
                {
                  this.state.success ?
                    <div className="ajax-response">Thanks for your message. We will get back to you shortly!</div> : ''
                }
              </form>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Contact