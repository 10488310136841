import React from "react"

const Services = () => {
  return (
    <section id="services" className="module module-gray">
      <div className="container">
        <div className="module-header" data-sal="slide-up">
          <h2 className="module-title">Our Services</h2>
          <div className="module-line"></div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <div className="iconbox" data-sal="slide-up">
              <div className="iconbox-icon">
                <span className="icon-s-1"/>
              </div>
              <div className="iconbox-text">
                <h3 className="iconbox-title">Product Design</h3>
                <div className="iconbox-desc">
                  We excel at crafting digital products which fit your requirements and provide value to your business.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="iconbox" data-sal="slide-up" data-sal-delay="100">
              <div className="iconbox-icon">
                <span className="icon-s-2"/>
              </div>
              <div className="iconbox-text">
                <h3 className="iconbox-title">Web Development</h3>
                <div className="iconbox-desc">
                  Our experienced full-stack development team can help you create your own blog or the next big e-commerce platform.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="iconbox" data-sal="slide-up" data-sal-delay="200">
              <div className="iconbox-icon">
                <span className="icon-s-3"/>
              </div>
              <div className="iconbox-text">
                <h3 className="iconbox-title">Branding</h3>
                <div className="iconbox-desc">
                  We provide Brand identity services that can help to differentiate your business from your competitors.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="iconbox" data-sal="slide-up" data-sal-delay="100">
              <div className="iconbox-icon">
                <span className="icon-s-4"/>
              </div>
              <div className="iconbox-text">
                <h3 className="iconbox-title">App Development</h3>
                <div className="iconbox-desc">
                  We can build cross platform and native mobile applications to bring you closer to your customers.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="iconbox" data-sal="slide-up" data-sal-delay="200">
              <div className="iconbox-icon">
                <span className="icon-s-5"/>
              </div>
              <div className="iconbox-text">
                <h3 className="iconbox-title">Product Refresh</h3>
                <div className="iconbox-desc">
                  Partner with us to refresh your existing solutions and bring your business to the current digital era.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="iconbox" data-sal="slide-up" data-sal-delay="300">
              <div className="iconbox-icon">
                <span className="icon-s-6" />
              </div>
              <div className="iconbox-text">
                <h3 className="iconbox-title">Cloud Consulting</h3>
                <div className="iconbox-desc">
                  We can help you adopt cloud computing, to reduce operational expenses and unlock new possibilities.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services