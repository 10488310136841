import React from "react"

const Hero = () => {
  return (
    <section id="home" className="home-banner module-image module-full-height">

      <div className="intro" data-sal="fade">
        <h1>We are Infinicube</h1>
      </div>

      <div className="mouse-icon">
        <div className="wheel"></div>
      </div>

    </section>
  )
}

export default Hero